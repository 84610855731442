import React, {FC, ReactElement} from "react";
import Slider from "react-slick";
import cls from "./builder_carousel.module.scss"
import {SliderNextArrow, SliderPrevArrow} from "../../../svg";
import ImageBoxCard from "./CarouselCards/ImageBoxCard";
import TestimonialCard from "./CarouselCards/TestimonialCard";
import BrandListCard from "./CarouselCards/BrandListCard";

interface IProps {
    item: {
        content: string,
        carousel: {
            cards: Array<Record<string, string>>
            settings: Record<string, string>
        }
    }
}

interface IArrow {
    onClick?: any,
    backgroundColor: string,
    color: string,
    type: string,
    eventListeners: {
        onMouseOverButton: (e: React.MouseEvent<HTMLElement>, type: string) => void,
        onMouseOutButton: (e: React.MouseEvent<HTMLElement>, type: string) => void
    }
}

const NextArrow: FC<IArrow> = (
    {
        onClick,
        color,
        backgroundColor,
        type,
        eventListeners: {
            onMouseOverButton,
            onMouseOutButton,
        }
    }
) => {
    return <div
        className={`${cls["slick_arrow_wrapper"]} ${cls.next}`}
        onClick={onClick}
        onMouseOver={(e) => onMouseOverButton(e, "svg")}
        onMouseOut={(e) => onMouseOutButton(e, "svg")}
    >
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            {type !== "BrandList" && <rect width="32" height="32" rx="16" fill={backgroundColor}/>}
            <path d="M13 22L19 16L13 10" stroke={color} stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round"/>
        </svg>
    </div>
}

const PrevArrow: FC<IArrow> = (
    {
        onClick,
        color,
        backgroundColor,
        type,
        eventListeners: {
            onMouseOverButton,
            onMouseOutButton,
        }
    }
) => {
    return <div
        className={`${cls["slick_arrow_wrapper"]} ${cls.prev}`}
        onClick={onClick}
        onMouseOver={(e) => onMouseOverButton(e, "svg")}
        onMouseOut={(e) => onMouseOutButton(e, "svg")}
    >
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            {type !== "BrandList" && <rect width="32" height="32" rx="16" fill={backgroundColor}/>}
            <path d="M19 22L13 16L19 10" stroke={color} stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round"/>
        </svg>
    </div>
}

const Carousel: FC<IProps> = ({item}) => {
    const {
        content = "",
        carousel: {
            cards = [{}],
            settings: {
                animation,
                contentColor,
                contentHoverColor,
                buttonFontColor,
                buttonBgColor,
                buttonHoverFontColor,
                buttonHoverBgColor,
            }
        }
    } = item

    const slidesToShow = content === "BrandList" ? 6 : 3
    const slidesToShow991 = content === "BrandList" ? 4 : 2
    const slidesToShow425 = content === "BrandList" ? 2 : 1

    const carouselSettings = {
        dots: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 2000,
        speed: 500,
        slidesToShow: slidesToShow,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: slidesToShow991,
                }
            },
            {
                breakpoint: 425,
                settings: {
                    slidesToShow: slidesToShow425,
                }
            }
        ]
    }

    const onMouseOver = (e: React.MouseEvent<HTMLDivElement>) => {
        (e.target as HTMLDivElement).style.color = contentHoverColor
    }

    const onMouseOut = (e: React.MouseEvent<HTMLDivElement>) => {
        (e.target as HTMLDivElement).style.color = contentColor
    }

    const onMouseOverButton = (e: React.MouseEvent<HTMLElement>, type: string) => {
        if (type === "svg") {
            if (content === "BrandList") {
                ((e.target as HTMLDivElement).children[0].children[0] as HTMLDivElement).style.stroke = buttonHoverFontColor
            } else {
                ((e.target as HTMLDivElement).children[0].children[0] as HTMLDivElement).style.fill = buttonHoverBgColor as string
                ((e.target as HTMLDivElement).children[0].children[1] as HTMLDivElement).style.stroke = buttonHoverFontColor
            }
        } else {
            (e.target as HTMLDivElement).style.backgroundColor = buttonHoverBgColor as string
            (e.target as HTMLDivElement).style.color = buttonHoverFontColor
        }
    }

    const onMouseOutButton = (e: React.MouseEvent<HTMLElement>, type: string) => {
        if (type === "svg") {
            if (content === "BrandList") {
                ((e.target as HTMLDivElement).children[0].children[0] as HTMLDivElement).style.stroke = buttonFontColor
            } else {
                ((e.target as HTMLDivElement).children[0].children[0] as HTMLDivElement).style.fill = buttonBgColor as string
                ((e.target as HTMLDivElement).children[0].children[1] as HTMLDivElement).style.stroke = buttonFontColor
            }
        } else {
            (e.target as HTMLDivElement).style.backgroundColor = buttonBgColor as string
            (e.target as HTMLDivElement).style.color = buttonFontColor
        }
    }

    const eventListeners = {
        contentStyle: {
            color: contentColor
        },
        buttonStyle: {
            color: buttonFontColor,
            backgroundColor: buttonBgColor,
        },
        onMouseOver,
        onMouseOut,
        onMouseOverButton,
        onMouseOutButton
    }

    const handleCarouselByType = (card: Record<string, string>): Record<string, ReactElement> => {
        return {
            "ImageBox": <ImageBoxCard card={card} eventListeners={eventListeners}/>,
            "Testimonials": <TestimonialCard card={card} eventListeners={eventListeners}/>,
            "BrandList": <BrandListCard card={card}/>
        }
    }

    const addFakeItems = (items: Array<Record<string, string>>) => {
        const fakeItemsQty = slidesToShow - items.length
        const fakeItemsArray = []

        for (let i = 0; i < fakeItemsQty; i++) {
            fakeItemsArray.push(i)
        }

        return fakeItemsArray.map(() => {
            return <div className={cls["carousel_card_wrapper"]}></div>
        })
    }

    return <div
        className={cls.carousel}
        data-aos={animation}
        // style={{height: item.slider.settings.height as number}}
    >
        <Slider
            {...carouselSettings}
            nextArrow={<NextArrow eventListeners={eventListeners} color={buttonFontColor}
                                  backgroundColor={buttonBgColor} type={content}/>}
            prevArrow={<PrevArrow eventListeners={eventListeners} color={buttonFontColor}
                                  backgroundColor={buttonBgColor} type={content}/>}
        >
            {cards.map(card => {
                return handleCarouselByType(card)[content]
            })}

            {addFakeItems(cards)}
        </Slider>
    </div>
}


export default Carousel