import React, {FC} from 'react'
import cls from "../forms.module.scss"
import {useDispatch, useSelector} from "react-redux";
import {setFormElementValue} from "../../../../store/formbuilder";
import {IState} from "../../../../types/state";
import {OtherInput} from "./index";
import ErrorMessages from "./ErrorMessages";

interface IProps {
    formId: number,
    index: number,
    element: {
        className: string,
        description: string,
        descriptionShow: boolean,
        inline: boolean,
        label: string,
        name: string,
        other: boolean,
        required: boolean,
        toggle: boolean,
        values: [
            {
                label: string,
                selected: boolean,
                value: string,
            }
        ],
    }
}

const Checkbox: FC<IProps> = (
    {
        formId,
        index,
        element: {
            className = "",
            description = "",
            descriptionShow = false,
            inline = false,
            label = "",
            name = "",
            other = false,
            required = false,
            toggle = false,
            values = [
                {
                    label: "",
                    selected: false,
                    value: ""
                }
            ],
        }
    }
) => {
    const reduxValues = useSelector((state: IState) => state.formBuilder.forms.find(el => el.id === formId)?.formData[index].values || [])
    const errors = useSelector((state: IState) => state.formBuilder.errors)
    const dispatch = useDispatch();

    const handleChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValues = reduxValues.map(el => {
            if (el.value === e.target.value) {
                return {
                    ...el,
                    selected: !el.selected
                }
            }
            return el
        })

        dispatch(setFormElementValue({
            id: formId,
            index,
            fieldsForChange: {
                values: newValues
            }
        }))
    }

    const toggleCheckbox = (label: string, selected: boolean, value: string) => {
        return <div className={cls.option} key={name + " " + value}>
            <div className={cls.toggle_border}>
                <div className={`${cls.toggle} ${selected ? cls.checked : " "}`}>
                    <input
                        key={name + " " + value}
                        id={name + " " + value}
                        type="checkbox"
                        name={name}
                        value={value}
                        checked={selected}
                        onChange={handleChangeValue}
                    />
                    <span className={cls.check}> </span>
                </div>
            </div>
            <label htmlFor={name + " " + value}>{label}</label>
        </div>
    }

    const hasOtherOption = {
        true: <OtherInput
            formId={formId}
            index={index}
            name={name}
            type="checkbox"
        />,
        false: null
    }

    const handleRequired = {
        true: <span className={cls.required}>*</span>,
        false: null
    }

    const handleDescription = {
        true: <div className={cls.description}>
            <span>?</span>
            <div className={cls.info_text}>{description}</div>
        </div>,
        false: null
    }

    const handleOptionsStyle: { true: {}, false: {} } = {
        true: {flexDirection: "row"},
        false: {flexDirection: "column"}
    }

    return <div className={`${cls.form_checkbox} ${className}`}>
        <div className={cls.label}>
            <label htmlFor={name}>
                {label}
            </label>
            {handleRequired[`${required}`]}
            {handleDescription[`${descriptionShow}`]}
        </div>
        <div className={cls.options} style={handleOptionsStyle[`${inline}`]}>
            {reduxValues.map(({
                                  label = "",
                                  selected = false,
                                  value = ""
                              }) => {
                const handleOther = {
                    true: {
                        true: toggleCheckbox(label, selected, value),
                        false: <div className={cls.option} key={name + " " + value}>
                            <input
                                id={name + " " + value}
                                type="checkbox"
                                name={name}
                                value={value}
                                checked={selected}
                                onChange={handleChangeValue}
                            />
                            <label htmlFor={name + " " + value}>{label}</label>
                        </div>
                    },
                    false: {
                        true: null,
                        false: null
                    }
                }

                return handleOther[`${label !== "other"}`][`${toggle}`]
            })}
            {hasOtherOption[`${other}`]}
        </div>
        <ErrorMessages errors={errors[name]}/>
    </div>
}
export default Checkbox
