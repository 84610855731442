import React, {FC} from 'react'
import cls from "../forms.module.scss"

interface IProps {
    formId: number,
    index: number,
    element: {
        subtype: string,
        className: string,
        label: string
    }
}

const Heading: FC<IProps> = (
    {
        formId,
        index,
        element: {
            subtype = "",
            className = "",
            label = ""
        }
    }
) => {
    return <div className={`${className} ${cls[`heading_${subtype}`]}`}>
        {label}
    </div>
}
export default Heading
