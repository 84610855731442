import React, {FC, useEffect, useState} from 'react'
import cls from "../forms.module.scss"
import {useDispatch, useSelector} from "react-redux";
import {setFormElementValue} from "../../../../store/formbuilder";
import {IState} from "../../../../types/state";

interface IProps {
    formId: number,
    index: number,
    name: string,
    type: string,
}

const OtherInput: FC<IProps> = (
    {
        formId,
        index,
        name,
        type
    }
) => {
    const reduxValues = useSelector((state: IState) => state.formBuilder.forms.find(el => el.id === formId)?.formData[index].values || [])
    const [otherChecked, setOtherChecked] = useState<boolean>(false)
    const [otherInputValue, setOtherInputValue] = useState<string>("")
    const dispatch = useDispatch();

    useEffect(() => {
        const hasOtherOption = reduxValues.find(el => el.label === "other")
        if (!hasOtherOption) {
            setOtherChecked(false)
            setOtherInputValue("")
        }
    }, [reduxValues]);

    const handleCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
        setOtherChecked(e.target.checked)

        const hasOtherOption = reduxValues.find(el => el.label === "other")

        dispatch(setFormElementValue({
            id: formId,
            index,
            fieldsForChange: {
                values: hasOtherOption ?
                    reduxValues.map(el => {
                        if (type === "checkbox") {
                            if (el.label === "other") {
                                return {
                                    ...el,
                                    selected: e.target.checked,
                                }
                            }
                            return el
                        }
                        return {
                            ...el,
                            selected: el.label === "other"
                        }
                    })
                    :
                    [
                        ...reduxValues.map(el => {
                            if (type === "checkbox") {
                                return el
                            }
                            return {
                                ...el,
                                selected: false
                            }
                        }),
                        {
                            label: "other",
                            value: "",
                            selected: e.target.checked,
                        }
                    ]
            }
        }))
    }

    const handleChangeOtherInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        setOtherInputValue(e.target.value)

        dispatch(setFormElementValue({
            id: formId,
            index,
            fieldsForChange: {
                values: reduxValues.map(el => {
                    if (el.label === "other") {
                        return {
                            ...el,
                            value: e.target.value,
                        }
                    }
                    return el
                })

            }
        }))
    }

    const handleOtherInput = {
        true: <input
            id="otherInput"
            type="text"
            className="form-control"
            value={otherInputValue}
            placeholder="your variant"
            onChange={handleChangeOtherInput}
        />,
        false: null
    }

    return <div className={cls.option}>
        <input
            id={`${name} other`}
            type={type}
            className={cls.other_input}
            name={name}
            onChange={handleCheck}
        />
        <label htmlFor={`${name} other`}>Other</label>
        {handleOtherInput[`${otherChecked || type === "radio"}`]}
    </div>
}
export default OtherInput
