import {useEffect, useMemo, useState} from "react";


export default function useIsInViewport(ref: { current: any; }) {
    const [isIntersecting, setIsIntersecting] = useState(false);


    useEffect(() => {
        const observer = new IntersectionObserver(([entry]) =>
            setIsIntersecting(entry.isIntersecting)
        );

            observer.observe(ref.current);


        return () => {
            observer.disconnect();
        };
    }, [ref]);

    return isIntersecting;

}