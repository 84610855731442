import React, {FC} from 'react'

const ErrorMessages: FC<{ errors: Array<string> }> = ({errors = []}) => {
    return <>
        {
            errors.map(error => {
                return <div className="alert-danger" key={error}>
                    {error}
                </div>
            })
        }
    </>
}
export default ErrorMessages
