import React, {FC} from "react";
import cls from "./shared.module.scss"

const BlockHeaderCustom: FC<any> = ({title}) => {

    return (
        title ? <div className={cls["block-header"]}>
            <h2 className={cls["block-header__title__custom"]}>
                <span className={cls["title-name-fms"]}>
                    {" "}
                    {title}
                </span>
            </h2>
        </div> : null
    );
}

export default BlockHeaderCustom;
