import {FC} from "react";

interface IProps {
    item: {
        textEditorValue: string;
        convertedSettings: {
            animationAos: { "data-aos": any },
            styles: any;
            parentStyles: any
        }
    }
}

const Editor: FC<IProps> = (
    {
        item: {
            textEditorValue,
            convertedSettings: {
                animationAos: {"data-aos": dataAos = null} = {},
                styles = {},
                parentStyles = {}
            } = {}
        }
    }
) => {
    return (
        <div data-aos={dataAos} style={parentStyles}>
            <div style={styles} dangerouslySetInnerHTML={{ __html: textEditorValue }}/>
        </div>
    );
};

export default Editor;
